<script setup lang="ts">
definePageMeta({ middleware: "guest-only" })
const { signIn, status } = useAuth();
const carregando = computed(() => status.value === "loading");
</script>

<template>
    <section v-if="carregando"> aguarde... </section>
    <section v-else>
        <!-- <p>
            <a href="/api/auth/signin"> Native Link Sign in </a>
        </p> -->
        <button @click="signIn(`zitadel`)">
            Sign In
        </button>
    </section>

    <Head>
        <Title> Inicio </Title>
    </Head>
</template>
